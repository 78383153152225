import React, { useState, useEffect } from "react";
import { ChakraProvider, Grid, GridItem, HStack, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Radio, RadioGroup, Switch } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "./Database";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  ListIOTDemosQuery,
  IOTDemo,

} from "../../API";
import { CSVLink, CSVDownload } from "react-csv";
import {
  Button,
  Box,
  FormControl,
  StackDivider,
  Input,
  Container,
  VStack,
  Stack,
  IconButton,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { withAuthenticator } from "@aws-amplify/ui-react";
// import { Pagination} from '../../components/Pagination'
import { Pagination, usePagination } from "@aws-amplify/ui-react";
import { SearchIcon, DownloadIcon } from '@chakra-ui/icons'

const IotReport = () => {

  type UnitConversion = {
    Time: string;
    Meters: string;
  };

  let nextToken: any = null
  // const [nextToken, setNextToken] = useState<string | null | undefined>(null);
  const [data, setData] = useState<IOTDemo[]>([]);
  const columnHelper = createColumnHelper<UnitConversion>();

  const [endDate, setEndDate] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<string | undefined>();
  const [contains, setContains] = useState<string>("00:00");

  let sumRes: any = [];

  const iotData: UnitConversion[] = data.map((item) => ({
    Time: moment(item.time).utc().format("DD-MM-YYYY HH:mm:ss"),
    Meters: JSON.parse(item.payload), // Add a default value for 'Meters' property

  }));

  const headers = [
    { label: "DateTime", key: "Time" },
    { label: "Meter1", key: "Meters.Meter1" },
    { label: "Meter2", key: "Meters.Meter2" },
    { label: "Meter3", key: "Meters.Meter3" },
    { label: "Meter4", key: "Meters.Meter4" },
    { label: "Meter5", key: "Meters.Meter5" },
    { label: "Meter6", key: "Meters.Meter6" },
    { label: "Meter7", key: "Meters.Meter7" },
    { label: "Meter8", key: "Meters.Meter8" },
    { label: "Meter9", key: "Meters.Meter9" },
    { label: "Meter10", key: "Meters.Meter10" },
    { label: "Meter11", key: "Meters.Meter11" },
    { label: "Meter12", key: "Meters.Meter12" },
    { label: "Meter13", key: "Meters.Meter13" },
    { label: "Meter14", key: "Meters.Meter14" },
    { label: "Meter15", key: "Meters.Meter15" },
    { label: "Meter16", key: "Meters.Meter16" },
    { label: "Meter17", key: "Meters.Meter17" },

  ];



  const csvReport = {
    data: iotData,
    headers: headers,
    filename: 'Clue_Mediator_Report.csv'
  };

  const testfunction = () => {
    console.log(iotData)
  }


  const fetchData = async () => {
    for (let i = 0; i < 10; i++) {
      console.log(i)
      try {

        const allTodos = await API.graphql<GraphQLQuery<ListIOTDemosQuery>>({
          query: queries.listIOTDemos,
          variables: {
            filter: {

              time: {

                ge: startDate,
                lt: endDate
              },
              and: {
                time: { contains: contains }
              }
            },
            limit: 100000,
            nextToken: nextToken,

          },
        });
        // setNextToken(allTodos.data?.listIOTDemos?.nextToken)
        nextToken = allTodos.data?.listIOTDemos?.nextToken

        if (allTodos.data?.listIOTDemos?.nextToken != null) {
          // setNextToken(allTodos.data?.listIOTDemos?.nextToken);
          nextToken = allTodos.data?.listIOTDemos?.nextToken
          console.log("setToken")
        }
        else {
          // setNextToken(null); 
          nextToken = null;
          i=1000
        }

        const res = allTodos.data?.listIOTDemos?.items.map(
          (item) => item as IOTDemo
        );

        if (res != undefined) {


          sumRes = ([...sumRes, ...res])

          // setData([...res]) 
          console.log(sumRes)
          // if( nextToken != null){fetchData()}
          
        }
      } catch (error) {
        console.error("An error occurred while making the API request:", error);
      }
    }
    return(sumRes)
  };

  const columns = [
    columnHelper.accessor("Time", {

      sortingFn: "auto",
      cell: (info) => info.getValue(),
      header: "-------Date Time------",

    }),
    ...Array.from({ length: 17 }, (_, i) =>
      columnHelper.accessor(`Meters.Meter${i + 1}` as keyof UnitConversion, {
        cell: (info) =>
          info.getValue() === undefined ? "No Data" : Math.floor(parseInt(info.getValue()) / 1000),
        header: `Meter${i + 1}` === undefined ? "No Meter" : `Meter${i + 1}`,
      })
    ),
  ];


  const handleSearch = async () => {
      let x:any = []
    x =await fetchData()
      setData(x)
     console.log(x)

    console.log(nextToken)
    //  if (nextToken != null) {
    //    handleSearch()

    //  }

    // testfunction()


  };




  return <>


    <VStack

      spacing={2}
      align='stretch'
    >
      <Stack direction={['row']} spacing='10px'>
        <Box >
          <Popover>
            <PopoverTrigger>

              <IconButton
                colorScheme='blue'
                aria-label='Search'
                icon={<SearchIcon />}
                size='lg'
              />

            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Select Date</PopoverHeader>
              <PopoverBody>
                <Box maxW='sm' borderWidth='1px' borderRadius='lg'>
                  <VStack

                    spacing={3}
                    shadow='md'
                    borderWidth='1px'
                    padding='2'
                    align='left'
                  >
                    <Text>Start Date</Text>
                    <Input
                      size="sm"
                      htmlSize={4}
                      width="auto"
                      type="datetime-local"
                      placeholder="Start Date"
                      onChange={(e) => setStartDate(e.target.value)}
                    />

                    <Text>End Date</Text>
                    <Input
                      size="sm"
                      htmlSize={4}
                      width="auto"
                      placeholder="End Date"
                      type="datetime-local"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                    <RadioGroup defaultValue='one hours'>
                      <HStack spacing='24px'>
                        <Radio value="15 mins" onChange={() => setContains("")}>15 mins</Radio>
                        <Radio value="one hours" onChange={() => setContains("00:00")}>1 hours</Radio>

                      </HStack>
                    </RadioGroup>

                    <Button size="sm" onClick={handleSearch}>
                      Search
                    </Button>

                  </VStack>

                </Box>

              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box >
        <Box >
          <CSVLink
            {...csvReport}
            filename={"MWGO-LMSReport.csv"}
            className="btn btn-primary"
          >

            <IconButton
              colorScheme='green'
              aria-label='exportCSV'
              icon={<DownloadIcon />}
              size='lg'
            > </IconButton>

          </CSVLink>
        </Box >
      </Stack>



      <Box height="600px" overflow="auto">

        {data.length != 0 ? (
          <div>
            <Text>Total Data: {data.length}</Text>
            <DataTable columns={columns} data={iotData} />  
          </div>
        )
          : "Please search and select Date"}

      </Box>


    </VStack>

  </>;
};

export default IotReport