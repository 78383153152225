import React, { useState, useEffect } from "react";
import { ChakraProvider, Grid, GridItem } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "./Database";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  ListIOTDemosQuery,
  SearchableIOTDemoConnection,
  IOTDemo,
  SearchIOTDemosQuery,
} from "../../API";
import {
  Button,
  Box,
  FormControl,
  StackDivider,
  Input,
  Container,
  VStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { withAuthenticator } from "@aws-amplify/ui-react";
// import { Pagination} from '../../components/Pagination'
import { Pagination, usePagination } from "@aws-amplify/ui-react";
type UnitConversion = {
  Time: string;
  Meters: string;
};

function IotDemo() {
  const columnHelper = createColumnHelper<UnitConversion>();
  const [data, setData] = useState<IOTDemo[]>([]);
  const [endDate, setEndDate] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<string | undefined>();
  const [limit, setLimit] = useState<number>(1000);
  const [totalCounts, setTotalCounts] = useState<number | null | undefined>(1);
  const [from, setFrom] = useState<number>(0);

  const [currentPageIndex, setCurrentPageIndex] = React.useState<number>(1);

  const handleSearch = () => {
    fetchData();
  };

  useEffect(() => {
    handleSearch();
  }, [from]);

  useEffect(() => {
    setFrom(currentPageIndex ? currentPageIndex * limit - limit : 0);
  }, [currentPageIndex]);

  const iotData: UnitConversion[] = data.map((item) => ({
    Time: moment(item.time).utc().format("DD-MM-YYYY HH:mm:ss"),
    Meters: JSON.parse(item.payload), // Add a default value for 'Meters' property
  }));

  async function fetchData() {
    try {
      console.log(`from is ${from}`);
      const allTodos = await API.graphql<GraphQLQuery<SearchIOTDemosQuery>>({
        query: queries.searchIOTDemos,
        variables: {
          filter: {
            time: {
              range: [
                `${startDate!}T00:00:00.000Z`,
                `${endDate!}T23:59:59.999Z`,
              ],
            },
          },
          from,
          limit,

          sortDirection: "DESC",
        },
      });

      console.log(allTodos);
      const res = allTodos.data?.searchIOTDemos?.items.map(
        (item) => item as IOTDemo
      );

      setTotalCounts(allTodos.data?.searchIOTDemos?.total);
      setData(res || []);
    } catch (error) {
      console.error("An error occurred while making the API request:", error);
    }
  }

  const columns = [
    columnHelper.accessor("Time", {
      cell: (info) => info.getValue(),
      header: "-------Date Time------",
    }),
    ...Array.from({ length: 17 }, (_, i) =>
      columnHelper.accessor(`Meters.Meter${i + 1}` as keyof UnitConversion, {
        cell: (info) =>
          info.getValue() === undefined ? "No Data" : Math.floor(parseInt(info.getValue())/1000),
        header: `Meter${i + 1}` === undefined ? "No Meter" : `Meter${i + 1}`,
      })
    ),
  ];

  const handleNextPage = () => {
    console.log("handleNextPage");

    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handlePreviousPage = () => {
    console.log("handlePreviousPage");

    setCurrentPageIndex(currentPageIndex - 1);
  };

  const handleOnChange = (
    newPageIndex: number | null | undefined,
    prevPageIndex: number | null | undefined
  ) => {
    setCurrentPageIndex(newPageIndex ? newPageIndex : 1);
  };

  return (

    <Grid
    h="200px"
    templateRows="repeat(2, 1fr)"
    templateColumns="repeat(9, 1fr)"
    gap={4}
  >
  
       
        <GridItem rowSpan={2} colSpan={1}>
        <Box maxW='sm' borderWidth='1px' borderRadius='lg'>
      <VStack
       
        spacing={3}
        shadow='md'
        borderWidth='1px'
        padding='2'
       align='left'
      >
          <Text>Start Date</Text>
          <Input
            size="sm"
            htmlSize={4}
            width="auto"
            type="date"
            placeholder="Start Date"
            onChange={(e) => setStartDate(e.target.value)}
          />

          <Text>End Date</Text>
          <Input
            size="sm"
            htmlSize={4}
            width="auto"
            placeholder="End Date"
            type="date"
            onChange={(e) => setEndDate(e.target.value)}
          />

          <Button  size="sm" onClick={handleSearch}>
            Search
          </Button>
          </VStack>
        </Box>

    </GridItem>

  
    {totalCounts?
        <GridItem colSpan={8}>
          <Box height="600px" overflow="auto">
          <Text>Total Data: {totalCounts}</Text>

          <DataTable columns={columns} data={iotData} />

          </Box>
          <Box overflow="auto">
        <Pagination
          currentPage={currentPageIndex}
          totalPages={totalCounts ? Math.ceil(totalCounts / limit) : 1}
          onNext={handleNextPage}
          onPrevious={handlePreviousPage}
          onChange={handleOnChange}
        />
      </Box>
        </GridItem>:'Plase select date and search'}

    </Grid>

)}

export default withAuthenticator(IotDemo);
