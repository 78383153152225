import {Dispatch, ReactNode, SetStateAction, createContext,useState} from "react"

export type UserInfo = {
    name:string|null|undefined
  
}

export interface UserContextInterface {
    userInfo:UserInfo,
    setUserInfo:Dispatch<SetStateAction<UserInfo>>

}

const defaultState = {
    userInfo:{
        name:'',

    },
    setUserInfo: (userInfo:UserInfo) => {}
} as UserContextInterface

export const UserContext = createContext(defaultState)
   
type UserProvideProps = {
    children: ReactNode
}


export default function UserProvider({children}: UserProvideProps)
{
    const [userInfo,setUserInfo] = useState<UserInfo>({
        name:''
    });

    return (
        <UserContext.Provider value = {{userInfo,setUserInfo}}>
            {children}
        </UserContext.Provider>
    )

}