import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react'
import { FiBell, FiSearch } from 'react-icons/fi'
import { Logo } from './Logo'
import { MobileDrawer } from './MobileDrawer'
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';


export const Navbar = () => {

  const { user, signOut } = useAuthenticator((context) => [context.user]);

  // async function checkUserLogin() {
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     return user.username;
  //   } catch (err) {
  //     return null;
  //   }
  // }

  // useEffect(() => {
  //   async function fetchUserName() {
  //     const user = await checkUserLogin();
  //     setUsername(user);
  //   }
  //   fetchUserName();
  // }, [username]);

  async function handleSignOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <Box as="section" minH="lg">
      <Box borderBottomWidth="1px" bg="bg.accent.default" position="relative" zIndex="tooltip">
        <Container py="4">
          <HStack justify="space-between" spacing="8">
            <HStack spacing="10">
              <HStack spacing="3">
                <MobileDrawer />
                <Logo />
              </HStack>
              <ButtonGroup
                size="lg"
                variant="text.accent"
                colorScheme="gray"
                spacing="8"
                display={{ base: 'none', lg: 'flex' }}
              >
                <Link to="/">
                  <Button>Home</Button>
                </Link>
                <Link to="/LMSupload">
                  <Button>LMS</Button>
                </Link>
                <Link to="/IotMain">
                  <Button>IOT</Button>
                </Link>
                {/* <DocumentPopover />
                <Button>History</Button> */}
              </ButtonGroup>
            </HStack>
            <HStack spacing={{ base: '2', md: '4' }}>
              <InputGroup maxW="2xs" display={{ base: 'none', md: 'inline-flex' }}>
                <InputLeftElement>
                  <Icon as={FiSearch} color="fg.accent.muted" fontSize="lg" />
                </InputLeftElement>
                <Input placeholder="Search" variant="filled.accent" />
              </InputGroup>
              <ButtonGroup variant="tertiary.accent" spacing="1">

              </ButtonGroup>

              {user && user.username ? (
                <>
                  <Text color='white'> {user.username}.</Text>
                  <Button onClick={handleSignOut}>Log Out</Button>

                </>
              ) : (
                <Link to="/login"><Button>Log In</Button></Link>
              )}

            </HStack>
          </HStack>

        </Container>
      </Box>
    </Box>
  );
};

export default Navbar;
