/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchIOTDemos = /* GraphQL */ `
  query SearchIOTDemos(
    $filter: SearchableIOTDemoFilterInput
    $sort: [SearchableIOTDemoSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableIOTDemoAggregationInput]
  ) {
    searchIOTDemos(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        time
        payload
   
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getSKGOPointLog = /* GraphQL */ `
  query GetSKGOPointLog($id: ID!) {
    getSKGOPointLog(id: $id) {
      id
      Timestamp
      PointDetails {
        PointName
        Value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSKGOPointLogs = /* GraphQL */ `
  query ListSKGOPointLogs(
    $filter: ModelSKGOPointLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSKGOPointLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Timestamp
        PointDetails {
          PointName
          Value
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIOTDemo = /* GraphQL */ `
  query GetIOTDemo($id: ID!, $time: AWSDateTime!) {
    getIOTDemo(id: $id, time: $time) {
      id
      time
      payload

      __typename
    }
  }
`;
export const listIOTDemos = /* GraphQL */ `
  query ListIOTDemos(
    $id: ID
    $time: ModelStringKeyConditionInput
    $filter: ModelIOTDemoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIOTDemos(
      id: $id
      time: $time
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        time
        payload
       
        __typename
      }
      nextToken
      __typename
    }
  }
`;
