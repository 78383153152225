import React, {useContext,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { theme  } from '@chakra-ui/pro-theme'
import { extendTheme } from '@chakra-ui/react'
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import UserProvider from './contexts/UserContext';
Amplify.configure(awsconfig);



const proTheme = extendTheme(theme)

const extenstion = {
  colors: { ...proTheme.colors, brand: proTheme.colors.blue },
  fonts: {
    heading: "'Inter Variable', -apple-system, system-ui, sans-serif",
    body: "'Inter Variable', -apple-system, system-ui, sans-serif",
  },
  
}
  

const myTheme = extendTheme(extenstion, proTheme)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={myTheme}>
      
      <UserProvider>
       <App />
       </UserProvider>
    </ChakraProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
