
import React from "react";
import { BrowserRouter, Routes, Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import LayoutComponent from './components/Layout';
import LMSUpload from './pages/LMSUpload';
import Login from './pages/Login';
import { LMSReport } from './pages/lms/LMSReport';
import LMSDelData from './pages/lms/LMSDelData';
import './App.css';
import  IotDemo from "./pages/iot/IotDemo";
import UserProvider from "./contexts/UserContext";
import Navbar from "./components/navbar/Navbar";
import { Authenticator } from '@aws-amplify/ui-react';
import IotReport from "./pages/iot/IotReport";
import IotReport3AND4 from "./pages/iot/IotReport3AND4";
import { IotMain } from "./pages/iot/IotMain";


const router = createBrowserRouter(createRoutesFromElements(

           <> 

    <Route path ="/" element={<LayoutComponent/>}>
        <Route index  element={<Home/>}/>
        <Route path="/login"  element={<Login/>}/>
        <Route path="/lmsUpload"  element={<LMSUpload/>}/>
        <Route path="/lmReport"  element={<LMSReport/>}/>
        <Route path="/lmsDelData"  element={<LMSDelData/>}/>
        <Route path=":number" element={<Profile/>}/>
        <Route path="/iotdemo" element={<IotDemo/>}/>
        <Route path="/iotreport" element={<IotReport/>}/>
        <Route path="/iotreport3&4" element={<IotReport3AND4/>}/>
        <Route path="/iotMain" element={<IotMain/>}/>
    </Route>

    </>
)
)



const App:React.FunctionComponent = () =>{
  
    return(

       

        <Authenticator.Provider>
    <RouterProvider router={router}/>
    </Authenticator.Provider>

    );

};
export default App;