import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import moment from "moment";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  ListSKGOPointLogsQuery,
  SKGOPointLog,
  DeleteSKGOPointLogMutation,
  DeleteSKGOPointLogInput,
} from "../../API";
import { Button } from "@chakra-ui/react";



const LMSDelData = () => {


  async function deleteAllData() {
    try {
      // 获取所有数据的id
      const allData = await API.graphql<GraphQLQuery<ListSKGOPointLogsQuery>>({
        query: queries.listSKGOPointLogs,
      });
      const allIds =
        allData.data?.listSKGOPointLogs?.items.map((item) => item?.id) || [];
   
      // 循环删除每个数据
      for (let id of allIds) {
        const skgoPointLogDetails: DeleteSKGOPointLogInput = {
          id: id!,
        };
        
        const deletedTodo = await API.graphql<
          GraphQLQuery<DeleteSKGOPointLogMutation>
        >({
          query: mutations.deleteSKGOPointLog,
          variables: { input: skgoPointLogDetails },
         
        });
      }
    } catch (error) {
      console.log("error on deleting data", error);
    } console.log("del 1 data");
  }

  return <>
  <Button onClick={deleteAllData}>del data</Button>
  <h1>hello</h1>
  </>;
};

export default LMSDelData;
