import React, { useState, useEffect, ReactNode } from "react";
import {
  ChakraProvider,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
} from "@chakra-ui/react";

import {
  Button,
  Box,
  FormControl,
  StackDivider,
  Input,
  Container,
  VStack,
  Stack,
  Text,
  Switch,
} from "@chakra-ui/react";
import moment from "moment";
import { withAuthenticator } from "@aws-amplify/ui-react";
import {
  iotfetchData,
  calculateDifference,
  iotReport1Table,
} from "./IotFunction";
import { SearchIcon,DownloadIcon } from '@chakra-ui/icons'
function IotReport3AND4() {
  const [monthYear, setMonthYear] = useState<string>();
  const [saturdayReportResult, setSaturdayReportResult] =useState<ReactNode>(null);
  const [sundayReportResult, setSundayReportResult] = useState<ReactNode>(null);
  const [officeHourReportResult, setOfficeHourReportResult] =useState<ReactNode>(null);
  const [nonofficeHourReportResult, setnonOfficeHourReportResult] =useState<ReactNode>(null);
  const [monthlyDataReportResult, setMonthlyDataHourReportResult] =useState<ReactNode>(null);
  const [showOfficeHourReport, setShowOfficeHourReport] =useState(true);
  const [showNonOfficeHourReport, setShowNonOfficeHourReport] =useState(true);
  const [showSaturayReport, setShowSaturayReport] =useState(true);
  const [showSundayReport, setShowSundayReport] =useState(true);
  const [showMonthlyReport, setShowmonthlyReport] =useState(true);
    
  function sumMeterByKey(
    objects: Record<string, number>[]
  ): Record<string, number> {
    const result: Record<string, number> = {};

    for (const obj of objects) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          result[key] = (result[key] || 0) + obj[key];
        }
      }
    }

    return result;
  }

      
  function subMeterByKey(
    objects: Record<string, number>[],
    objects2:any
  ): Record<string, number> {
  
     const result = objects2

    for (const obj of objects) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
         
          result[key] = (result[key] || 0) - obj[key];
        }
      }
    }

    return result;
  }




  function getWeekEnd(year: number, month: number, day: number): Date[] {
    const result: Date[] = [];
    const date = new Date(year, month - 1, 1);
    while (date.getMonth() === month - 1) {
      if (date.getDay() === day) {
        result.push(new Date(date));
      }
      date.setDate(date.getDate() + 1);
    }
    return result;
  }

  function getWeekday(year: number, month: number): Date[] {
    const result: Date[] = [];
    const date = new Date(year, month - 1, 1);
    while (date.getMonth() === month - 1) {
      if (date.getDay() != 0 && date.getDay() != 6) {
        result.push(new Date(date));
      }
      date.setDate(date.getDate() + 1);
    }
    return result;
  }

  const handleSearch = async () => {
    const getDate = new Date(monthYear ? monthYear : "2013");
    const Month = getDate.getMonth() + 1;
    const Year = getDate.getFullYear();

    const saturdays = getWeekEnd(Year, Month, 6).map(
      (item) => `${item.getFullYear()}-${item.getMonth() + 1}-${item.getDate().toString().padStart(2, '0')}`
    );
    const sundays = getWeekEnd(Year, Month, 0).map(
      (item) => `${item.getFullYear()}-${item.getMonth() + 1}-${item.getDate().toString().padStart(2, '0')}`
    );
    const weekdays = getWeekday(Year, Month).map(
      (item) => `${item.getFullYear()}-${item.getMonth() + 1}-${item.getDate().toString().padStart(2, '0')}`
    );

    const nextMonthly = [`${Year}-${Month+1}`]

   

    const saturdayStartDatas = await Promise.all(
      saturdays.map((date) => iotfetchData(`${date}T00:00:00.000Z`))
    );
    const saturdayEndDatas = await Promise.all(
      saturdays.map((date) => iotfetchData(`${date}T24:59:00.000Z`))
    );

    const sundaysStartDatas = await Promise.all(
      sundays.map((date) => iotfetchData(`${date}T00:00:00.000Z`))
    );
    const sundaysEndDatas = await Promise.all(
      sundays.map((date) => iotfetchData(`${date}T24:59:00.000Z`))
    );

    const offHourStartDatas = await Promise.all(
      weekdays.map((date) => iotfetchData(`${date}T08:59:00.000Z`))
    );
    console.log(offHourStartDatas)
    const offHourEndDatas = await Promise.all(
      weekdays.map((date) => iotfetchData(`${date}T17:59:00.000Z`))
    );
    console.log(offHourEndDatas)
    const monthlyStartDatas = await Promise.all(
      nextMonthly.map((date) => iotfetchData(`${monthYear}-01T00:00:00.000Z`))
    );
    const monthlyEndDatas = await Promise.all(
      // nextMonthly.map((date) => iotfetchData(`${nextMonthly}T00:00:00.000Z`))
      nextMonthly.map((date) => iotfetchData(`${nextMonthly}-01T00:00:00.000Z`))

    );


    let saturdayData = saturdayStartDatas.map((item, index) => {
      if (item != undefined && item.length > 0) {
        return calculateDifference(item, saturdayEndDatas[index]);
      }
      return {};
    });

    let sundayData = sundaysStartDatas.map((item, index) => {
      if (item != undefined && item.length > 0) {
        return calculateDifference(item, sundaysEndDatas[index]);
      }
      return {};
    });

    let officeHourtData = offHourStartDatas.map((item, index) => {
      if (item != undefined && item.length > 0) {
        return calculateDifference(item, offHourEndDatas[index]);
      }
      return {};
    });
    
    console.log(officeHourtData)
    let monthlyData = monthlyStartDatas.map((item, index) => {
      if (item != undefined && item.length > 0) {
        return calculateDifference(item, monthlyEndDatas[index]);
      }
      return {};
    });
    setMonthlyDataHourReportResult(iotReport1Table(monthlyData[0]));

    const saturdayReportData = sumMeterByKey(saturdayData);
    const sundayReportData = sumMeterByKey(sundayData);
    const officeHourReportData = sumMeterByKey(officeHourtData);

    const sumArray = saturdayData.concat(sundayData,officeHourtData);
    const nonOfficeHourReportData = subMeterByKey(sumArray,monthlyData[0])
    
    setSaturdayReportResult(iotReport1Table(saturdayReportData));
    setSundayReportResult(iotReport1Table(sundayReportData));
    setOfficeHourReportResult(iotReport1Table(officeHourReportData));
    setnonOfficeHourReportResult(iotReport1Table(nonOfficeHourReportData));


   

  };

  return (
    <>
      
      <VStack
  
  spacing={2}
  align='stretch'
>
  <Box>
      <Popover>
          <PopoverTrigger>

            <IconButton
              colorScheme='blue'
              aria-label='Search database'
              icon={<SearchIcon />}
              size='lg'
            />

          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow/>
            <PopoverCloseButton />
            <PopoverHeader>Select Date</PopoverHeader>
            <PopoverBody>
              
            <Box maxW='sm' borderWidth='1px' borderRadius='lg'>
          <VStack
           
            spacing={3}
            shadow='md'
            borderWidth='1px'
            padding='2'
           align='left'
          >
              <FormControl>
                <FormLabel >Select Month:</FormLabel>
                <Input
                  type="month"
              
                  onChange={(e) => setMonthYear(e.target.value)}
              
                />
              </FormControl>
         

 
              <Button onClick={handleSearch}>Search</Button>
              <Text>Show/ Hide</Text>
              <Switch defaultChecked onChange={()=>setShowOfficeHourReport(!showOfficeHourReport)}>Office Hour</Switch>
              <Switch defaultChecked onChange={()=>setShowNonOfficeHourReport(!showNonOfficeHourReport)}>NonOffice</Switch>
              <Switch defaultChecked onChange={()=>setShowSaturayReport(!showSaturayReport)}>Saturday</Switch>
              <Switch defaultChecked onChange={()=>setShowSundayReport(!showSundayReport)}>Sunday Hour</Switch>
              <Switch defaultChecked onChange={()=>setShowmonthlyReport(!showMonthlyReport)}>Monthly</Switch>
              </VStack>
            </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        </Box>
  
          

        <SimpleGrid columns ={4} minChildWidth="250px" spacing={10}>
        {monthlyDataReportResult ? <>
        {showOfficeHourReport?
        
          <Box height="600px" overflow="auto">
            <Text
              backgroundColor="blue.700"
              textColor="white"
              textAlign="center"
            >
              Office Hour Report
            </Text>
            {officeHourReportResult
              ? officeHourReportResult
              : "Please select date"}
          </Box>
       :''}
        {showNonOfficeHourReport?
        
          <Box height="600px">
            <Text
              backgroundColor="red.700"
              textColor="white"
              textAlign="center"
            >
              Non Office Hour Report
            </Text>
            {nonofficeHourReportResult ? nonofficeHourReportResult : "Please search and select Date"}
          </Box>
       :''}
        {showSaturayReport?
       
          <Box height="600px">
            <Text
              backgroundColor="blue.700"
              textColor="white"
              textAlign="center"
            >
              Saturday Report
            </Text>
            {saturdayReportResult ? saturdayReportResult : "Please select date"}
          </Box>
       :''}
    
        {showSundayReport?
          <Box height="600px">
            <Text
              backgroundColor="red.700"
              textColor="white"
              textAlign="center"
            >
              Sunday Report
            </Text>
            {sundayReportResult ? sundayReportResult : "Please select date"}
          </Box>
       :''}
        {showMonthlyReport?
       
          <Box height="600px">
            <Text
              backgroundColor="blue.700"
              textColor="white"
              textAlign="center"
            >
              Monthly Report
            </Text>
            {monthlyDataReportResult ? monthlyDataReportResult : "Please select date"}
          </Box>
      :''}
        </>:'Plase select date and search'}

        </SimpleGrid>
        </VStack>
  
    </>
  );
}

export default withAuthenticator(IotReport3AND4);
