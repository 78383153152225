

import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';

import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import '@aws-amplify/ui-react/styles.css';
import Navbar from '../components/navbar/Navbar';
Amplify.configure(awsconfig);


export function Login({ signOut, user }: WithAuthenticatorProps) {
 




  return (
    <>
 
 Welcome, {user?.username}!
    </>
  );
}

export default withAuthenticator(Login);