import React,{useState} from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, FormControl, FormLabel, Input, Container } from '@chakra-ui/react'

import IotReport3AND4 from './IotReport3AND4'
import IotDemo from './IotDemo'
import IotReport from './IotReport'
export const IotMain = () => {
    const [monthYear, setMonthYear] = useState<string>();
  return (
    <Container>
    <Tabs >
    <TabList>
      <Tab>Database</Tab>
      <Tab>Reprot</Tab>


    </TabList>
    <TabPanels>
      <TabPanel>
        <IotReport/>
      </TabPanel>
      <TabPanel>
      <IotReport3AND4/>
      </TabPanel>
    </TabPanels>
  </Tabs>
  </Container>
  )
}
