import { chakra, type HTMLChakraProps } from '@chakra-ui/react'

export const Logo = (props: HTMLChakraProps<'svg'>) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 130 130" width="40" height="40"
  fillRule="evenodd"
  clipRule="evenodd"
  imageRendering="optimizeQuality"
  shapeRendering="geometricPrecision"
  textRendering="geometricPrecision"
  
>
  <path
    fill="#88b285"
    d="M101.5 6.5c-1.88.841-3.88 1.175-6 1a351.626 351.626 0 00-53 0c-.667 0-1-.333-1-1 20-1.333 40-1.333 60 0z"
    opacity="0.392"
  ></path>
  <path
    fill="#6ebe69"
    d="M101.5 6.5a826.64 826.64 0 0126.5 45c1.009 1.525 1.509 3.192 1.5 5h-1c-.958-.453-1.792-1.12-2.5-2a463.395 463.395 0 01-52.5.5c-3.536-3.907-5.87-8.407-7-13.5 2.412-.17 4.579.497 6.5 2 2.805-1.097 5.638-1.097 8.5 0 7.502-2.85 15.17-3.517 23-2a73.114 73.114 0 00-2-15 4.932 4.932 0 01-3-.5 55.626 55.626 0 00-7-8 842.79 842.79 0 01-41-1.5 27.215 27.215 0 01-6-8c16.653-1 33.32-1.333 50-1 2.12.175 4.12-.159 6-1z"
    opacity="0.998"
  ></path>
  <path
    fill="#7cb879"
    d="M95.5 7.5c-16.68-.333-33.347 0-50 1a27.215 27.215 0 006 8 842.79 842.79 0 0041 1.5 55.626 55.626 0 017 8c.948.483 1.948.65 3 .5a73.114 73.114 0 012 15c-7.83-1.517-15.498-.85-23 2-2.862-1.097-5.695-1.097-8.5 0-1.921-1.503-4.088-2.17-6.5-2 1.13 5.093 3.464 9.593 7 13.5 17.574.825 35.074.658 52.5-.5.708.88 1.542 1.547 2.5 2-18.838 1.328-37.838 1.328-57 0-3.36-5.684-6.694-11.35-10-17a420.863 420.863 0 0141-1 71.165 71.165 0 00-10-18.5l-43-1a56.963 56.963 0 01-7-11.5 351.626 351.626 0 0153 0z"
    opacity="0.996"
  ></path>
  <path
    fill="#6aaa83"
    d="M21.5 38.5c.543.06.876.393 1 1a173.472 173.472 0 01-9 21c1.437 2.077 2.104 4.41 2 7a50.764 50.764 0 01-5-6.5c3.58-7.59 7.246-15.09 11-22.5z"
    opacity="0.995"
  ></path>
  <path
    fill="#59ad76"
    d="M39.5 7.5c2.64 4.316 5.306 8.65 8 13a76349.593 76349.593 0 00-22 38c7.347 12.699 14.68 25.365 22 38-.535 1.761-1.535 3.094-3 4-1.385 1.074-2.551 2.407-3.5 4a36.643 36.643 0 00-6.5-4 67.247 67.247 0 00-6.5-16 42.706 42.706 0 01-9-14c-.816-1.323-1.983-1.99-3.5-2v-1c.104-2.59-.563-4.923-2-7a173.472 173.472 0 009-21c-.124-.607-.457-.94-1-1a247.998 247.998 0 0018-31z"
    opacity="0.996"
  ></path>
  <path
    fill="#cbf6d6"
    d="M61.5 39.5c3.306 5.65 6.64 11.316 10 17 19.162 1.328 38.162 1.328 57 0h1v3h-2c-18.679-.333-37.345 0-56 1a94.7 94.7 0 00-9 16h-1c0-.667-.333-1-1-1a206.805 206.805 0 019-17c-3.329-5.99-6.662-11.99-10-18l2-1z"
    opacity="0.996"
  ></path>
  <path
    fill="#62a97d"
    d="M59.5 40.5c3.338 6.01 6.671 12.01 10 18a206.805 206.805 0 00-9 17c-.897 1.098-1.73 1.098-2.5 0A89.038 89.038 0 0148.5 58 454.76 454.76 0 0158 40.5c.69-1.238 1.19-1.238 1.5 0z"
    opacity="0.996"
  ></path>
  <path
    fill="#56af73"
    d="M56.5 46.5h3c-.163 2.357.003 4.69.5 7 2.98 1.036 5.48 2.702 7.5 5a64.677 64.677 0 00-4.5 9c-2.044 1.272-3.878.939-5.5-1-.326-2.01-.659-4.01-1-6a6.845 6.845 0 01-4 1 73.333 73.333 0 014-15z"
    opacity="0.996"
  ></path>
  <path
    fill="#046a39"
    d="M127.5 59.5h2a8.43 8.43 0 01-.5 4l-24 42c-1.067 2.192-2.567 3.858-4.5 5 0-.667-.333-1-1-1 1.756-3.146 3.089-6.146 4-9-.855-.793-1.689-.46-2.5 1l-.5-1.5c1.524-1.194 2.69-2.694 3.5-4.5.811 1.46 1.645 1.793 2.5 1a28.907 28.907 0 01-1.5-4c-1.147.958-2.147.625-3-1l-1 2a11.596 11.596 0 01.5-7c1.96-.948 3.96-1.115 6-.5-1.915-3.077-2.581-6.244-2-9.5a8739.034 8739.034 0 01-32-2 7.291 7.291 0 01-2-3 63.976 63.976 0 013-9 339.887 339.887 0 0144-.5c2.547.067 4.714.9 6.5 2.5l2.5-5z"
    opacity="0.996"
  ></path>
  <path
    fill="#7dab8e"
    d="M15.5 68.5c1.517.01 2.684.677 3.5 2a42.706 42.706 0 009 14 67.247 67.247 0 016.5 16 21959.422 21959.422 0 01-19-32z"
    opacity="0.996"
  ></path>
  <path
    fill="#15633d"
    d="M127.5 59.5l-2.5 5c-1.786-1.6-3.953-2.433-6.5-2.5a339.887 339.887 0 00-44 .5 63.976 63.976 0 00-3 9 7.291 7.291 0 002 3c10.697.688 21.364 1.355 32 2-.581 3.256.085 6.423 2 9.5-2.04-.615-4.04-.448-6 .5-2.731 3.124-4.731 6.79-6 11-15.7.696-31.366 1.363-47 2 .11-.617.444-1.117 1-1.5a970.239 970.239 0 0044-1.5 607.488 607.488 0 0010-17.5c-.228-.399-.562-.565-1-.5l-40-2a94.7 94.7 0 019-16c18.655-1 37.321-1.333 56-1z"
    opacity="0.996"
  ></path>
  <path
    fill="#6da28a"
    d="M61.5 76.5h1l40 2c-14.04.649-27.708-.018-41-2z"
    opacity="0.996"
  ></path>
  <path
    fill="#cbecd6"
    d="M41.5 6.5c0 .667.333 1 1 1a56.963 56.963 0 007 11.5l43 1a71.165 71.165 0 0110 18.5 420.863 420.863 0 00-41 1l-2 1c-.31-1.238-.81-1.238-1.5 0A454.76 454.76 0 0048.5 58 89.038 89.038 0 0058 75.5c.77 1.098 1.603 1.098 2.5 0 .667 0 1 .333 1 1 13.292 1.982 26.96 2.649 41 2 .438-.065.772.101 1 .5a607.488 607.488 0 01-10 17.5 970.239 970.239 0 01-44 1.5c-.556.383-.89.883-1 1.5a36.17 36.17 0 00-4 7c-1.1 1.105-1.767 2.438-2 4-1.88.271-3.213-.396-4-2 3.058-1.743 5.058-4.41 6-8 1.465-.906 2.465-2.239 3-4a76349.593 76349.593 0 01-22-38c7.347-12.699 14.68-25.365 22-38-2.694-4.35-5.36-8.684-8-13l2-1z"
    opacity="0.996"
  ></path>
  <path
    fill="#617c6d"
    d="M29.5 55.5c5.475.989 11.142 1.322 17 1-5.846 1.317-11.846 1.317-18 0 .124-.607.457-.94 1-1z"
    opacity="0.996"
  ></path>
  <path
    fill="#0e331d"
    d="M98.5 34.5l-37 2c-1.478-.262-2.811.071-4 1a159.012 159.012 0 00-11 19c-5.858.322-11.525-.011-17-1a54045.84 54045.84 0 0019-32 308.732 308.732 0 0143-.5 207.467 207.467 0 017 11.5z"
    opacity="0.996"
  ></path>
  <path
    fill="#495e4f"
    d="M98.5 34.5c.543.06.876.393 1 1-12.65 1-25.316 1.332-38 1l37-2z"
    opacity="0.996"
  ></path>
  <path
    fill="#0f351f"
    d="M29.5 59.5a73.123 73.123 0 0117 1 267.415 267.415 0 0011 19l42 2a272.917 272.917 0 01-8 13.5c-14 .667-28 .667-42 0a1918.485 1918.485 0 01-20-35.5z"
    opacity="0.996"
  ></path>
  <path
    fill="#056838"
    d="M101.5 86.5a11.596 11.596 0 00-.5 7l1-2c.853 1.625 1.853 1.958 3 1a28.907 28.907 0 001.5 4c-.855.793-1.689.46-2.5-1-.81 1.806-1.976 3.306-3.5 4.5l.5 1.5c.811-1.46 1.645-1.793 2.5-1-.911 2.854-2.244 5.854-4 9h-54c.237-1.291-.096-2.291-1-3a36.17 36.17 0 014-7c15.634-.637 31.3-1.304 47-2 1.269-4.21 3.269-7.876 6-11z"
    opacity="0.996"
  ></path>
  <path
    fill="#74aa8a"
    d="M34.5 100.5a36.643 36.643 0 016.5 4c.949-1.593 2.115-2.926 3.5-4-.942 3.59-2.942 6.257-6 8l-4-8z"
    opacity="0.996"
  ></path>
  <path
    fill="#1f533a"
    d="M44.5 106.5c.904.709 1.237 1.709 1 3h54c.667 0 1 .333 1 1h-58c.233-1.562.9-2.895 2-4z"
    opacity="0.996"
  ></path>
</svg>
);


