
import { Container } from "@chakra-ui/react";
import React, {useContext,useEffect} from 'react';
import UserProvider,{ UserContext }  from "../contexts/UserContext";
import Login from "./Login";
import Navbar from "../components/navbar/Navbar";


function UserDetails(){

  const {userInfo} = useContext(UserContext)

  return(
    <>
    <p>User Name:{userInfo.name}</p>
   
    </>
  )
}


// function AnotherComponent(){

//   const {userInfo, setUserInfo} = useContext(UserContext)

//   useEffect(()=>{
//     setUserInfo({
//       name:'sam',
//       email:'goo@mail.com'
//     })
// },[])

//   return(
//     <>
//     <p>some other components</p>
//     </>
//   )
// }





const Home = () => {


  return (
    

    <div>
      <Container maxW="4xl">
      <h1>Cube Control Co., Limited</h1>
      <h1> Flat 2111, 21/F, Wang Fai Industrial Building, 29 Luk Hop Street, San Po Kong, Kowloon
T : 27266730 , F000 :</h1>
    <h1>test2</h1>
</Container>

    </div>
  );
};

export default Home;
