import React, { useState, useEffect, ReactNode } from "react";

import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  ListIOTDemosQuery,
  SearchableIOTDemoConnection,
  IOTDemo,
  SearchIOTDemosQuery,
} from "../../API";


interface Payload {
  [key: string]: any;
}

export const iotfetchData = async (Date: string) => {
  try {
    const allTodos = await API.graphql<GraphQLQuery<ListIOTDemosQuery>>({
      query: queries.listIOTDemos,
      variables: {
        sortDirection: "ASC",
        id: "MWGO",
        time: {
          ge: Date,
        },
        limit: 2,
      },
    });
    const res = allTodos.data?.listIOTDemos?.items.map(
      (item) => item as IOTDemo
    );

    return res;
  } catch (error) {
    console.error("An error occurred while making the API request:", error);
  }
};

const mapJsonToOject = (data: Payload) => {
  let res: {} = data.map((item: any) => {
    return JSON.parse(item.payload);
  });

  return res;
};
export const calculateDifference = (startData: any, endData: any) => {
  const startDataToOject: Payload = mapJsonToOject(startData);
  const endDataToOject: Payload = mapJsonToOject(endData);
  const startPayload: Payload = {
    ...startDataToOject[1],
    ...startDataToOject[0],
  };

  const endPayload: Payload = { ...endDataToOject[1], ...endDataToOject[0] };

  const difference: Payload = {};

  for (const key in endPayload) {
    difference[key] = Math.floor((endPayload[key] - startPayload[key])/1000 );
  }

  const sortedDifference = Object.fromEntries(
    Object.entries(difference).sort((a, b) => {
      const keyA = a[0].replace("Meter", "");
      const keyB = b[0].replace("Meter", "");

      return Number(keyA) - Number(keyB);
    })
  );

  return sortedDifference;

  // for (const [key, value] of sortedDifference) {

  //   res += `${key} = ${value} kWh\n`;

  // }
  // res = <h1>heloo</h1>

  // return res
};

export const iotReport1Table = (data: Payload): ReactNode => {
  return (
    <Table className="table table-bordered" size='sm'>
      <Thead>
        <Tr>
          <Th>Meter</Th>
          <Th>kWh</Th>
        </Tr>
      </Thead>
      <Tbody>
        {Object.entries(data).map((key) => (
          <Tr key={key[0]}>
            <Td>{key[0]}</Td>
            <Td>{key[1]}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
